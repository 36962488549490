@import "../../local_modules/normalize.css/normalize";

:root {
  --blue-color: #1757FF;
  --gray-color: #A8A8A8;
  --dark-gray-color: #646464;
}

*,
*::before,
*::after {
    box-sizing: border-box;
}

html {
  box-sizing: border-box;
  overflow-x: hidden;
}

body {
  margin: 0!important;
  background: #F4F4F4;
  overflow-x: hidden;
}

img {
    max-width: 100%;
}

a {
  text-decoration: none;
  transition: all 0.2s;
  color: #000;
  outline: none;
  &:hover {
    color: var(--blue-color);
  }
}

h2 {
  font-size: 51px;
  font-weight: 700;
  line-height: 66.3px;
  text-align: left;
  margin: 0;
}

body {
  font-family: 'Gilroy';
  font-size: 16px;
  color: #000;
  font-weight: 400;
  letter-spacing: -0.02em;
  overflow-x: hidden;
}


.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
  width: 100%;
}

.btn-border {
  font-size: 16px;
  font-weight: 600;
  line-height: 19.6px;
  text-align: left;
  color: var(--blue-color);
  padding: 12px 20px;
  border: 2px solid var(--blue-color);
  border-radius: 10px;
  outline: none;
  display: flex;
  align-items: center;
  &:hover {
    background: var(--blue-color);
    color: #fff;
    img {
      filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%) hue-rotate(2deg) brightness(101%) contrast(103%);
    }
  }
  img {
    margin-right: 10px;
    transition: all 0.2s;
  }
}

.btn-blue {
  font-size: 16px;
  font-weight: 600;
  line-height: 19.6px;
  text-align: left;
  color: #fff;
  padding: 12px 20px;
  border: 2px solid var(--blue-color);
  border-radius: 10px;
  outline: none;
  background: var(--blue-color);
  display: inline-block;
  &:hover {
    background: transparent;
    color: var(--blue-color);
  }
}

.section-banner {
  border-radius: 0 0 40px 40px;
  background: #fff;
  padding-bottom: 30px;
  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__image {
    width: 48%;
    position: relative;
    overflow: hidden;
    border-radius: 50px;
    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.2);
    }
    img {
      height: 484px;
      display: block;
      object-fit: cover;
    }
  }
  &__content {
    width: 48%;
    &_buttons {
      display: flex;
      align-items: center;
      a {
        margin-right: 15px;
      }
    }
    h1 {
      font-size: 51px;
      font-weight: 700;
      line-height: 38px;
      text-align: left;
      color: var(--blue-color);
    }
    p {
      font-size: 22px;
      font-weight: 400;
      line-height: 28px;
      text-align: left;
      color: var(--dark-gray-color);
      max-width: 510px;
      margin-bottom: 42px;
    }
  }
}

.content-block {
  position: absolute;
  left: 15px;
  bottom: 15px;
  padding: 14px 24px;
  border-radius: 41px;
  background: #fff;
  z-index: 20;
  display: flex;
  align-items: center;
  span {
    font-size: 16px;
    font-weight: 600;
    line-height: 19.6px;
    text-align: left;
    color: var(--blue-color);
  }
  &__images {
    display: flex;
    align-items: center;
    margin-right: 8px;
    img {
      width: 54px;
      height: 54px;
      border-radius: 100%;
      object-fit: cover;
      border: 2px solid var(--blue-color);
      &:not(:first-child) {
        margin-left: -10px;
      }
      &:nth-child(2n) {
        position: relative;
        z-index: 18;
      }
    }
  }
}

.section-advantages {
  margin: 60px 0;
  &__wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
    margin-bottom: 40px;
  }
  &__item {
    padding: 25px 30px;
    border-radius: 15px;
    background: #fff;
    display: flex;
    align-items: center;
    img {
      width: 44px;
      height: 44px;
      margin-right: 13px;
      display: block;
    }
    span {
      font-size: 16px;
      font-weight: 400;
      line-height: 23px;
      text-align: left;
      max-width: 440px;
    }
  }
  &__button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
}

.swiper-container {
  position: relative;
}

.section-interface {
  margin: 60px 0;
  .swiper-container {
    padding-bottom: 40px;
    .swiper-slide {
      width: auto;
    }
  }
  &__head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 40px;
  }
  &__buttons {
    display: flex;
    align-items: center;
    .swiper-button {
      width: 37px;
      height: 37px;
      position: relative;
      left: auto;
      top: auto;
      bottom: auto;
      right: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 2px solid var(--blue-color);
      border-radius: 6px;
      &:after {
        font-size: 14px;
        font-weight: 800;
        color: var(--blue-color);
      }
    }
    .swiper-button-prev {
      &:after {
        margin-right: 2px;
      }
    }
    .swiper-button-next {
      margin-left: 10px;
      &:after {
        margin-left: 2px;
      }
    }
  }
}

.section-work {
  &__head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 40px;
  }
}

.section-work {
  padding: 80px 0;
  background: #fff;
  border-radius: 40px;
  .swiper-container {
    overflow: hidden;
    padding: 30px;
    background: #F4F4F4;
    border-radius: 30px;
    & * {
      box-sizing: border-box;
    }
    .swiper-slide {
      position: relative;
      height: 100%;
    }
    .swiper-button {
      width: 69px;
      height: 69px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 2.5px solid var(--blue-color);
      border-radius: 6px;
      &:after {
        font-size: 25px;
        font-weight: 800;
        color: var(--blue-color);
      }
    }
    .swiper-button-prev {
      top: auto;
      bottom: 30px;
      left: auto;
      right: 114px;
      &:after {
        margin-right: 2px;
      }
    }
    .swiper-button-next {
      top: auto;
      bottom: 30px;
      left: auto;
      right: 30px;
      &:after {
        margin-left: 2px;
      }
    }
  }
  &__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    &_image {
      width: 49%;
      min-height: 600px;
      display: flex;
      align-items: center;
      img {
        max-width: 100%;
      }
    }
    &_content {
      width: 49%;
      margin-bottom: auto;
      padding: 33px 38px;
      color: #fff;
      background: var(--blue-color);
      border-radius: 15px;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
    }
    &_label {
      width: 167px;
      padding: 8.5px;
      border-radius: 10px;
      background: #fff;
      position: absolute;
      left: 0;
      top: 0;
      display: flex;
      align-items: center;
      border: 1px solid #959595;
      p {
        font-size: 16px;
        font-weight: 500;
        line-height: 16px;
        text-align: center;
        margin: 0;
        width: 143px;
        color: #959595;
      }
      span {
        font-size: 12px;
        font-weight: 500;
        line-height: 15.6px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 16px;
        height: 14px;
        border: 1px solid #959595;
        border-radius: 4px;
        color: #959595;
      }
    }
    &_label.green-color {
      border-color: #94BA17;
      p {
        color: #94BA17;
      }
      span {
        border-color: #94BA17;
        color: #94BA17;
      }
    }
    &_label.purple-color {
      border-color: #B014D7;
      p {
        color: #B014D7;
      }
      span {
        border-color: #B014D7;
        color: #B014D7;
      }
    }
    &_label.orange-color {
      border-color: #FF8A00;
      p {
        color: #FF8A00;
      }
      span {
        border-color: #FF8A00;
        color: #FF8A00;
      }
    }
  }
}

.work-content {
  &__column {
    width: calc(100% - 85px);
    span {
      font-size: 22px;
      font-weight: 700;
      line-height: 28.6px;
      text-align: left;
      margin-bottom: 6px;
      display: inline-block;
    }
    p {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      text-align: left;
      margin: 0;
      max-width: 354px;
    }
  }
}

.section-blocks {
  margin: 60px 0;
  &__wrapper {
    display: flex;
    border-radius: 30px;
    background: #fff;
  }
  &__item {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 55px;
    border-radius: 30px;
    &:first-child {
      background: var(--blue-color);
      span {
        color: #fff;
      }
      p {
        color: #fff;
      }
    }
    &:nth-child(2) {
      span {
        margin-bottom: 30px;
      }
      a {
        min-width: 70%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    span {
      font-size: 40px;
      font-weight: 700;
      line-height: 46px;
      letter-spacing: 0.03em;
      text-align: left;
      margin-bottom: 15px;
      display: inline-block;
    }
    p {
      font-size: 26px;
      font-weight: 500;
      line-height: 30px;
      letter-spacing: 0.03em;
      text-align: center;
      margin: 0;
    }
  }
}

.section-line {
  margin: 60px 0;
  .swiper-wrapper {
    -webkit-transition-timing-function: linear !important;
    transition-timing-function: linear !important;
  }

  .swiper-slide img{
    max-width: 100%;
  }
  .swiper-slide {
    height: 290px;
    width: 500px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 16px;
    }
  }
  #to-right {
    margin-bottom: 20px;
  }
}

.section-advantges-bottom {
  margin: 60px 0;
  &__head {
    margin-bottom: 40px;
  }
  &__wrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 23px;
  }
  &__item {
    padding: 35px;
    border-radius: 15px;
    background: #fff;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border: 1px solid transparent;
    &:last-child {
      background: var(--blue-color);
      color: #fff;
      font-size: 32px;
      font-weight: 600;
      line-height: 41.6px;
      text-align: left;
      position: relative;
      &:hover {
        border-color: var(--blue-color);
        background: #fff;
        color: var(--blue-color);
        &:before {
          filter: brightness(0) saturate(100%) invert(21%) sepia(97%) saturate(2902%) hue-rotate(223deg) brightness(101%) contrast(101%);
        }
      }
      &:before {
        content: '';
        position: absolute;
        width: 41px;
        height: 27px;
        right: 35px;
        bottom: 35px;
        background: url("../img/arrows.svg") no-repeat center center;
        background-size: contain;
        transition: all 0.2s;
      }
    }
    img {
      width: 44px;
      height: 44px;
      margin-bottom: 20px;
    }
    span {
      font-size: 22px;
      font-weight: 600;
      line-height: 28.6px;
      text-align: left;
      max-width: 250px;
    }
  }
}

.section-for-clients {
  margin: 60px 0;
  &__head {
    margin-bottom: 17px;
  }
  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__content {
    width: 48%;
    h2 {
      margin-bottom: 40px;
    }
    p {
      font-size: 18px;
      font-weight: 400;
      line-height: 27.9px;
      text-align: left;
      margin: 0;
      &:not(:last-child) {
        margin-bottom: 25px;
      }
    }
  }
  &__image {
    width: 48%;
    display: flex;
    justify-content: flex-end;
  }
}
.section-garanty {
  margin: 60px 0;
  &__head {
    margin-bottom: 40px;
    h2 {
      font-size: 40px;
      font-weight: 700;
      line-height: 49.52px;
      text-align: left;
    }
  }
  &__wrapper-top {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 23px;
  }
  &__item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 35px;
    background: #fff;
    border-radius: 15px;
    img {
      margin-bottom: 25px;
    }
    span {
      font-size: 22px;
      font-weight: 600;
      line-height: 28.6px;
      text-align: left;
      max-width: 210px;
    }
  }
  &__wrapper-bottom {
    margin-top: 23px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 23px;
    .section-garanty__item {
      padding: 65px;
    }
    p {
      font-size: 22px;
      font-weight: 700;
      line-height: 28.6px;
      text-align: left;
      margin-top: 0;
      margin-bottom: 38px;
    }
    ul {
      margin: 0;
      padding: 0;
      list-style: none;
      li {
        padding-left: 40px;
        position: relative;
        font-size: 18px;
        font-weight: 500;
        line-height: 23.4px;
        text-align: left;
        &:not(:last-child) {
          margin-bottom: 15px;
        }
        &:before {
          content: '';
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          width: 30px;
          height: 24px;
          background: url('../img/mark.svg') no-repeat center center;
          background-size: contain;
        }
      }
    }
  }
  &__button {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 40px;
  }
}

.section-reviews {
  margin: 60px 0;
  &__head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 40px;
  }
  &__buttons {
    display: flex;
    align-items: center;
    .swiper-button {
      width: 37px;
      height: 37px;
      position: relative;
      left: auto;
      top: auto;
      bottom: auto;
      right: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 2px solid var(--blue-color);
      border-radius: 6px;
      &:after {
        font-size: 14px;
        font-weight: 800;
        color: var(--blue-color);
      }
    }
    .swiper-button-prev {
      &:after {
        margin-right: 2px;
      }
    }
    .swiper-button-next {
      margin-left: 10px;
      &:after {
        margin-left: 2px;
      }
    }
  }
  &__item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 25px;
    border-radius: 10px;
    background: #fff;
    box-shadow: 0px 0px 20px 0px #00000012;
    width: 340px;
    &_head {
      display: flex;
      align-items: center;
      margin-bottom: 12px;
      img {
        width: 40px;
        height: 40px;
        border-radius: 100%;
        border: 1px solid var(--blue-color);
        margin-right: 13px;
      }
      span {
        font-size: 16px;
        font-weight: 700;
        line-height: 20.8px;
        text-align: left;
      }
    }
    &_content {
      margin-bottom: 12px;
      p {
        font-size: 14px;
        font-weight: 400;
        line-height: 21.7px;
        text-align: left;
        margin: 0;
      }
    }
    &_date {
      font-size: 14px;
      font-weight: 500;
      line-height: 18.2px;
      text-align: left;
      color: var(--dark-gray-color);
      margin-left: auto;
    }
  }
}

.section-you {
  margin: 60px 0;
  &__content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    h2 {
      font-size: 40px;
      font-weight: 700;
      line-height: 49.52px;
      text-align: left;
      margin-bottom: 27px;
    }
    p {
      font-size: 18px;
      font-weight: 400;
      line-height: 27.9px;
      text-align: left;
    }
    ul {
      margin: 0;
      padding: 0;
      list-style: none;
      column-count: 2;
      li {
        padding-left: 44px;
        font-size: 18px;
        font-weight: 500;
        line-height: 23.4px;
        text-align: left;
        margin-bottom: 24px;
        position: relative;
        height: 34px;
        display: flex;
        align-items: center;
        &:before {
          content: '';
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          width: 34px;
          height: 34px;
          background: url("../img/fill-mark.svg") no-repeat center center;
          background-size: contain;
        }
      }
    }
  }
}


.section-feedback {
  margin: 60px 0;
  &__wrapper {
    padding: 70px 80px;
    border-radius: 20px;
    background: #fff;
    position: relative;
    overflow: hidden;
    &:after {
      content: '';
      position: absolute;
      right: 36px;
      bottom: 0;
      width: 288px;
      height: 350px;
      background: url("../img/feed.png") no-repeat center center;
      background-size: contain;
    }
    h2 {
      font-size: 30px;
      font-weight: 600;
      line-height: 36.75px;
      text-align: left;
      margin-bottom: 15px;
      position: relative;
      z-index: 16;
    }
    p {
      max-width: 632px;
      font-size: 18px;
      font-weight: 400;
      line-height: 20px;
      text-align: left;
      margin: 0;
      margin-bottom: 35px;
      position: relative;
      z-index: 16;
    }
    form {
      position: relative;
      z-index: 16;
      display: grid;
      grid-template-columns: repeat(7, 1fr);
      grid-gap: 20px;
      align-items: center;
      width: 100%;
      margin-bottom: 10px;
      input {
        width: 100%;
        padding: 12px 30px;
        color: var(--dark-gray-color);
        border: 1px solid var(--dark-gray-color);
        border-radius: 10px;
      }
      .form-group {
        &:first-child {
          grid-column: span 3 / auto;
        }
        &:nth-child(2) {
          grid-column: span 3 / auto;
        }
        button {
          font-family: 'Gilroy';
          padding: 9px 20px;
          transition: all 0.2s;
          cursor: pointer;
        }
      }
    }
  }
  span {
    font-size: 14px;
    font-weight: 400;
    line-height: 21.7px;
    text-align: left;
    position: relative;
    z-index: 16;
    a {
      color: var(--blue-color);
    }
  }
}


@media screen and (max-width: 970px) {
  .section-banner__wrapper {
    flex-direction: column;
  }
  .section-banner__content {
    width: 100%;
    margin-bottom: 25px;
  }
  .section-banner__image {
    width: 100%;
    border-radius: 30px;
    img {
      border-radius: 30px;
    }
  }
  .section-banner__content h1 {
    font-size: 30px;
    font-weight: 800;
    line-height: 36px;
    text-align: center;
    margin-bottom: 15px;
  }
  .section-banner__content p {
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
    text-align: center;
  }
  .section-banner__content_buttons {
    flex-direction: column;
    align-items: center;
  }
  .section-banner__content_buttons a {
    margin-right: 0;
    margin-bottom: 15px;
  }
  .content-block {
    padding: 13px 24px;
  }
  .content-block {
    right: 15px;
  }
  .content-block__images img {
    width: 35px;
    height: 35px;
  }
  .section-advantages__wrapper {
    grid-template-columns: repeat(1,1fr);
  }
  h2 {
    font-size: 30px;
    font-weight: 800;
    line-height: 36px;
    text-align: left;
  }
  .swiper-button {
    margin: 0;
  }
  .section-interface__head {
    flex-direction: column;
    align-items: flex-start;
    h2 {
      margin-bottom: 20px;
    }
  }
  .section-work__head {
    a {
      display: none;
    }
  }
  .section-work__item {
    flex-direction: column-reverse;
  }
  .section-work__item_image {
    width: 100%;
  }
  .section-work__item_content {
    width: 100%;
    flex-direction: column;
    position: relative;
  }
  .work-content__column {
    width: 100%;
  }
  .work-content__icon {
    img {
      width: 46px;
    }
  }
  .section-work .swiper-container {
    padding: 25px 15px;
  }
  .work-content {
    padding: 20px;
  }
  .work-content__column span {
    position: absolute;
    height: 57px;
    left: 80px;
    top: 10px;
    display: flex;
    align-items: center;
  }
  .work-content__column p {
    margin-top: 20px;
  }
  .section-work .swiper-container .swiper-button {
    width: 69px;
    height: 69px;
  }
  .section-work .swiper-container .swiper-button-prev {
    right: auto;
    left: 30px;
  }
  .section-work .swiper-container .swiper-pagination {
    display: none;
  }
  .section-work__item_label {
    top: 210px;
  }
  .section-blocks__wrapper {
    flex-direction: column;
  }
  .section-blocks__item {
    width: 100%;
    padding-left: 27px;
    padding-right: 27px;
  }
  .section-blocks__item span {
    font-size: 30px;
    font-weight: 700;
    line-height: 26px;
    letter-spacing: 0.03em;
    text-align: center;

  }
  .section-blocks__item:first-child p {
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0.03em;
    text-align: center;

  }
  .section-line .swiper-slide {
    width: 207px;
    height: 120px;
  }
  .section-advantges-bottom__wrapper, .section-garanty__wrapper-top, .section-garanty__wrapper-bottom {
    grid-template-columns: repeat(1,1fr);
  }
  .section-advantges-bottom__item:last-child {
    min-height: 192px;
  }
  .section-for-clients__wrapper {
    flex-direction: column;
  }
  .section-for-clients__content {
    width: 100%;
    margin-bottom: 30px;
  }
  .section-for-clients__image {
    width: 100%;
  }
  .section-garanty__wrapper-bottom .section-garanty__item {
    padding: 55px 35px;
  }
  .section-reviews__head {
    flex-direction: column;
    align-items: flex-start;
    h2 {
      margin-bottom: 20px;
    }
  }
  .section-you__content ul {
    column-count: 1;
  }
  .section-you__content h2 {
    font-size: 30px;
    font-weight: 800;
    line-height: 36px;
    text-align: left;

  }
  .section-garanty__head h2 {
    font-size: 30px;
    font-weight: 800;
    line-height: 36px;
    text-align: left;

  }
  .section-feedback__wrapper {
    padding: 40px 24px;

  }
  .section-feedback__wrapper:after {
    position: relative;
    right: auto;
    left: 50%;
    transform: translateX(-50%);
    display: block;
    bottom: -40px;
  }
  .section-feedback__wrapper h2 {
    font-size: 24px;
    font-weight: 600;
    line-height: 29.4px;
    text-align: center;
  }
  .section-feedback__wrapper p {
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;

  }
  .section-feedback__wrapper form .form-group:first-child, .section-feedback__wrapper form .form-group:nth-child(2), .section-feedback__wrapper form .form-group:last-child {
    grid-column: span 7/auto;
  }
  .section-feedback__wrapper form .form-group button {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .section-feedback span {
    font-size: 14px;
    font-weight: 400;
    line-height: 21.7px;
    text-align: center;
    display: inline-block;
  }
  .footer-top {
    flex-direction: column;
  }
  .footer-top__logo {
    margin-bottom: 30px;
  }
  .footer-top__center {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 30px;
    a {
      margin-bottom: 15px!important;
      display: inline-block;
    }
  }
  .footer-bottom {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;

  }
}
