@import "../mixins/media";
header {
  background: #fff;
}
.header-main {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 29px 0;
  &__logo {
    display: flex;
    align-items: center;
    img {
      margin-right: 16px;
    }
    span {
      font-size: 16px;
      font-weight: 500;
      line-height: 19px;
      text-align: left;
      max-width: 110px;
      color: var(--gray-color);
    }
  }
  &__block {
    display: flex;
    align-items: center;
  }
  &__menu {
    display: flex;
    align-items: center;
    list-style: none;
    padding: 0;
    margin: 0;
    li {
      &:not(:last-child) {
        margin-right: 40px;
      }
      a {
        font-size: 18px;
        font-weight: 600;
        line-height: 22.05px;
        text-align: center;
      }
    }
  }
  &__block {
    a {
      margin-left: 14px;
    }
  }
}

.mobile {
  display: none;
}

.modal-menu {
  display: none;
}



@media screen and (max-width: 1110px) {
  .header-main__menu li a {
    font-size: 16px;
  }
  .header-main__menu li:not(:last-child) {
    margin-right: 20px;
  }
  .header-main__logo span {
    font-size: 14px;
    line-height: 16px;
  }
  .header-main__logo img {
    max-width: 62px;
  }
}

@media screen and (max-width: 980px) {
  .mobile {
    display: flex;
  }
  .burger {
    display: flex;
    align-items: center;
    img {
      margin-right: 8px;
    }
    span {
      font-size: 18px;
      font-weight: 600;
      line-height: 38px;
      letter-spacing: 0.03em;
      text-align: left;
      color: var(--blue-color);
    }

  }
  .header-main {
    position: relative;
    &__logo {
      img {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translateY(-50%) translateX(-50%);
      }
    }
  }
  .header-main__menu {
    display: none;
  }
  .header-main__block {
    display: none;
  }
  .header-main__logo span {
    text-align: right;
  }
  .mask {
    width: 100%;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    background: #9090906b;
    transition: all 0.2s;
    opacity: 0;
    z-index: -1;
  }

  .mask.modal-show {
    opacity: 1;
    z-index: 12;
  }
  .slinky-menu {
    overflow: unset;
    min-height: unset;
  }

  .no-scroll {
    height: 100%;
    width: 100%;
    position: fixed;
    overflow: hidden;
  }

  .modal-menu {
    width: 346px;
    max-width: 100%;
    height: 100vh;
    position: fixed;
    left: -347px;
    top: 0;
    background: #fff;
    transition: all 0.2s;
    z-index: 14;
    display: flex;
    .container {
      overflow-x: unset;
    }
    .container {
      overflow-y: scroll;
      margin: 0px 15px;
      padding: 0;
      padding-bottom: 40px;
    }

  }
  .slinky-theme-default a:not(.back) {
    span {
      transition: all 0.4s;
    }
    .quantity {
      transition: all 0.4s;
    }
  }
  .slinky-theme-default a:not(.back):hover {
    background: transparent;
    color: var(--blue-color);
    span {
      color: var(--blue-color);
    }
  }

  .modal-show {
    left: 0;
  }
  .slinky-theme-default {
    background: none;
    width: 100%;
  }
  .slinky-menu ul {
    padding: 13px 0;
  }
  .slinky-menu .for-separator {
    position: relative;
    padding-top: 24px;
    margin-top: 24px;
  }
  .slinky-menu .for-separator:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 1px;
    background: radial-gradient(50% 50% at 50% 100%, #FFF 0%, rgba(255, 255, 255, 0.00) 100%), radial-gradient(86.82% 86.82% at 50% 0.91%, #99979E 0%, rgba(153, 151, 158, 0.00) 100%);
    backdrop-filter: blur(21px);

  }
  .slinky-theme-default .next::after {
    background: url("../img/dropdown-arrow.svg") no-repeat center center;
    background-size: 6px;
  }
  .slinky-theme-default .back::before {
    margin-left: -10px;
  }
  .slinky-theme-default .next:hover::after, .slinky-theme-default .back:hover::before {
    opacity: 1;
    filter: invert(0.5) sepia(1) saturate(5) hue-rotate(186deg);
  }
  .slinky-theme-default li {
    border-bottom: 1px solid transparent;
  }
  .active:after, .submenu-link:after {
    display: none;
  }
  .js-menu span {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .slinky-theme-default a:not(.back) {
    padding: 8px 0;
    font-size: 16px;
    font-weight: 500;
    line-height: 27px;
    letter-spacing: 0.03em;
    text-align: left;
  }
  .js-menu span {
    color: var(--main-black-color);
  }
  .modal-menu {
    color: var(--main-black-color)!important;
    &__block {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      position: relative;
      padding-top: 24px;
      &_row {
        display: flex;
        a {
          &:first-child {
            margin-right: 14px;
          }
        }
      }
      &_column {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 30px;
        span {
          color: var(--blue-color);
          font-size: 15px;
          font-weight: 400;
          line-height: 18px;
          letter-spacing: 0em;
          text-align: left;
          display: inline-block;
          margin-bottom: 10px;
        }
        .menu-strong {
          font-size: 17px;
          font-weight: 700;
          line-height: 21px;
          letter-spacing: 0em;
          text-align: left;
        }
        ul {
          list-style: none;
          padding: 0;
          margin: 0;
          display: flex;
          align-items: center;
          li {
            margin-right: 16px;
            filter: invert(.5) sepia(1) saturate(5) hue-rotate(196deg);
            img {
              max-width: 30px;
              max-height: 30px;
            }
          }
        }
      }
      &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 1px;
        background: radial-gradient(50% 50% at 50% 100%, #FFF 0%, rgba(255, 255, 255, 0.00) 100%), radial-gradient(86.82% 86.82% at 50% 0.91%, #99979E 0%, rgba(153, 151, 158, 0.00) 100%);
        backdrop-filter: blur(21px);
      }
    }
    &__social {
      margin-top: 40px;
      ul {
        list-style: none;
        padding: 0;
        margin: 0;
        display: flex;
        li {
          margin-right: 10px;
        }
      }
    }
    &__column {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
    .header-bottom__contact {
      display: flex;
      margin-bottom: 15px;
    }
    .header-bottom__right {
      margin-bottom: 25px;
      .comparison, .cabinet, .favorites {
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #D0D0D0;
        border-radius: 5px;
        span {
          display: none;
        }
        &:hover {
          border-color: var(--blue-color);

        }
        svg {
          width: 15px;
          height: 15px;
          transition: all 0.4s;
        }
      }
      .comparison {
        &:hover {
          svg {
            rect {
              fill: var(--blue-color);
            }
          }
        }
      }
      .cabinet {
        &:hover {
          svg {
            path {
              fill: var(--blue-color);
            }
          }
        }
      }
      .favorites {
        &:hover {
          svg {
            path {
              stroke: var(--blue-color);
            }
          }
        }
      }
    }
  }
  .modal-menu .container {
    &::-webkit-scrollbar {
      width: 0px;
    }
    &::-webkit-scrollbar-track {
      background: transparent;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #d4d4d4;
      border-radius: 20px;
    }
  }
}
