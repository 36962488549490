footer {
  padding: 30px 0;
  background: var(--blue-color);
}

.footer-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 38px;
  &__logo {
    display: flex;
    align-items: center;
    img {
      margin-right: 21px;
    }
    span {
      font-size: 16px;
      font-weight: 400;
      line-height: 22px;
      text-align: left;
      max-width: 100px;
      color: #fff;
    }
  }
  &__center {
    a {
      font-size: 18px;
      font-weight: 700;
      line-height: 21.09px;
      text-align: center;
      margin: 0 38px;
      color: #fff;
      &:hover {
        opacity: 0.7;
      }
    }
  }
  &__buttons {
    display: flex;
    align-items: center;
    .btn-border {
      border-color: #fff;
      color: #fff;
      margin-right: 20px;
      &:hover {
        background: #fff;
        color: var(--blue-color);
      }
    }
    .btn-blue {
      background: #fff;
      color: var(--blue-color);
      border-color: #fff;
      &:hover {
        background: transparent;
        border-color: #fff;
        color: #fff;
      }
    }
  }
}

.footer-bottom {
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  text-align: center;
  width: 100%;
  color: #fff;
}
